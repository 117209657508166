import get from 'lodash/get';
import pickBy from 'lodash/pickBy';

import { VehicleStatus } from '~lib/enum';

// https://stackoverflow.com/a/38873788/14957
export const nodeIsVisible = ($node) => {
	// If node was hidden by us, consider it visible so we can find it if we
	// scrape more than once.
	return Boolean(
		$node.dataset.autofiHidden || $node.offsetParent || $node.offsetHeight || $node.getClientRects().length
	);
};

export const getFirstVisible = (selector, $container = document) => {
	return [...$container.querySelectorAll(selector)].find(nodeIsVisible);
};

export const replaceDealerButton = ($dealerButton, $replacement) => {
	$dealerButton.dataset.autofiHidden = 'true';
	$dealerButton.style.display = 'none';
	$dealerButton.parentNode.insertBefore($replacement, $dealerButton);
};

/**
 * Return an array of matching nodes
 * @param {Node} $container within which to look for selected nodes
 * @param {string} selector query selector, or 'SELF' to take the container itself
 */
export const selectNodes = ($container, selector) => {
	if (selector === 'SELF') {
		return [$container];
	} else {
		return [...$container.querySelectorAll(selector)];
	}
};

/**
 * Determine whether or not the given node satisfies the text search
 * If the search term is empty, always return true
 * @param {string} textSearch text to search for inside node
 * @param {Node} $node node to evaluate
 * @return {boolean} whether the node matches the search (always true if no search text is provided)
 */
export const nodeTextFilter = (textSearch, $node) => {
	const lowerCaseNodeText = get($node, 'textContent', '').toLowerCase();
	return !textSearch || lowerCaseNodeText.includes(textSearch.toLowerCase());
};

/**
 * Extract a particular attribute value from the given node
 * If it's a URL attribute (src, href), make sure it's absolute
 * @param {Node} $node
 * @param {string} attribute
 */
export const getNodeAttribute = ($node, attribute) => {
	return ['href', 'src'].includes(attribute) ? get($node, attribute) : $node.getAttribute(attribute);
};

/**
 * If a regex is provided, match it against the value, otherwise return the value
 * - If a match is found, return the capture group for the provided index
 * - If no index is provided, return the whole regex match (index 0)
 * - If no match is found, return undefined
 * @param {string|null} value
 * @param {RegExp} regex
 * @param {Number} index
 */
export const extractValueByRegex = (value, regex, index) => {
	if (!regex || value === null) {
		return value;
	}
	return get(value.match(regex), parseInt(index, 10) || 0);
};

/**
 * Use the provided spec to extract a piece of data from inside $container
 * @param {object} dataSpec a dataSpec object (see models/Dealer/websiteSettings.js)
 * @param {string} dataSpec.dataType one of 'dataLayer', 'attribute' or 'textContent'
 * @param {string} dataSpec.selector node or dataLayer path to extract from
 * @param {string} dataSpec.attribute for attribute dataType, the attribute to grab
 * @param {string} dataSpec.extractionPattern regex to extract data for textContent dataType
 * @param {string} dataSpec.extractionIndex capture group index in extractionPattern
 * @param {Node} $container
 */
export const extractDataFromSingleSpec = (dataSpec, $container) => {
	const { attribute, dataType, enabled, extractionIndex, extractionPattern, selector } = dataSpec;
	const regex = extractionPattern ? new RegExp(extractionPattern, 'i') : null;
	if (!enabled) {
		return undefined;
	} else if (dataType === 'dataLayer') {
		return get(window, selector);
	} else if (dataType === 'attribute') {
		return selectNodes($container, selector)
			.map(($node) => getNodeAttribute($node, attribute))
			.map((value) => extractValueByRegex(value, regex, extractionIndex))
			.find(Boolean);
	} else if (dataType === 'textContent') {
		return selectNodes($container, selector)
			.map(($node) => extractValueByRegex($node.textContent, regex, extractionIndex))
			.find(Boolean);
	} else {
		throw Error(`Invalid dataType: ${dataType}`);
	}
};

/**
 * Extract a piece of data by inspecting each spec in dataSpecs until a truthy value is found
 * @param {array} dataSpecs array of dataSpec objects (see models/Dealer/websiteSettings.js)
 * @param {Node} $container to search in
 * @return {string} or undefined if no value is found
 */
export const extractDataFromSpecs = (dataSpecs, $container = document) => {
	return (dataSpecs || [])
		.filter((dataSpec) => dataSpec.enabled)
		.map((dataSpec) => extractDataFromSingleSpec(dataSpec, $container))
		.find(Boolean);
};

/**
 * Extract all of the dealer CTAs matching the given specs from the container
 * @param {array} ctaSpecs array of ctaSpec objects (see models/Dealer/websiteSettings.js)
 * @param {Node} $container
 * @return {object} object full of cta arrays, keyed by cta type (e.g. startApplication)
 */
export const getDealerCTAsFromSpecs = (ctaSpecs, $container = document) => {
	const ctaDict = {};
	ctaSpecs
		.filter((ctaSpec) => ctaSpec.enabled)
		.forEach((ctaSpec) => {
			const $ctas = selectNodes($container, ctaSpec.selector).filter(($node) =>
				nodeTextFilter(ctaSpec.textSearch, $node)
			);

			// TODO: assign these attributes outside of this function, to make it more
			// flexible and less surprising.
			$ctas.forEach(($cta) => {
				Object.assign($cta.dataset, makeOverrideDataset(ctaSpec));

				if (!ctaSpec.disableRemovingEventHandlersByCloning) {
					$cta.classList.add('autofi-cta-clone');
				}
			});

			ctaDict[ctaSpec.ctaType] = [...(ctaDict[ctaSpec.ctaType] || []), ...$ctas];
		});
	return ctaDict;
};

export const parseSalePrice = (salePrice) => {
	// find the substring contains dollar or other currency sign
	const price = (salePrice || '').match(/[$](\d+,?.?)+/);
	return price ? Number(price[0].replace(/[^0-9.]+/g, '')) : null;
};

export const getMatchingButtonSpecAndTarget = (buttonSpecs, $container = document) => {
	const enabledSpecs = buttonSpecs.filter((spec) => spec.enabled);

	for (const buttonSpec of enabledSpecs) {
		const { selector, textSearch } = buttonSpec;
		const $nodes = selectNodes($container, selector);
		const $target = $nodes.find(($node) => nodeTextFilter(textSearch, $node) && nodeIsVisible($node));
		if ($target) {
			return { buttonSpec, $target };
		}
	}
	return [];
};

export const makeButtonContainer = (ctaSpec) => {
	const $buttonContainer = document.createElement('div');
	const containerClass = containerClassFromCtaType(ctaSpec.ctaType);
	$buttonContainer.classList.add(containerClass);
	$buttonContainer.dataset.autofiCtaType = ctaSpec.ctaType;
	Object.assign($buttonContainer.dataset, makeOverrideDataset(ctaSpec));
	return $buttonContainer;
};

/**
 * Search for the first matching ctaSpec, and follow its instructions to
 * inject our button container.
 * @param {array} buttonSpecs array of buttonSpec objects (see models/Dealer/websiteSettings.js)
 * @param {string} ctaType
 * @param {Node} $containingContainer the node to search / inject into
 * @return {object} the injected button container and the matching ctaSpec
 */
export const placeButtonContainerFromSpecs = (buttonSpecs, ctaType, $containingContainer = document) => {
	const containerClass = containerClassFromCtaType(ctaType);
	const $existingButtonContainer = getFirstVisible(
		`.${containerClass}[data-autofi-cta-type=${ctaType}]`,
		$containingContainer
	);
	if ($existingButtonContainer) {
		// TODO: maybe figure out the matching ctaSpec and return it
		return { $ctaContainer: $existingButtonContainer };
	}

	const relevantSpecs = buttonSpecs.filter((spec) => spec.enabled && spec.ctaType === ctaType);
	const { buttonSpec: ctaSpec, $target } = getMatchingButtonSpecAndTarget(relevantSpecs, $containingContainer);

	if ($target) {
		const { placement } = ctaSpec;
		const $ctaContainer = makeButtonContainer(ctaSpec);
		const functionMap = {
			replace: () => replaceDealerButton($target, $ctaContainer),
			before: () => $target.parentElement.insertBefore($ctaContainer, $target),
			after: () => $target.parentElement.insertBefore($ctaContainer, $target.nextElementSibling),
			top: () => $target.insertBefore($ctaContainer, $target.firstChild),
			bottom: () => $target.appendChild($ctaContainer),
		};
		if (placement in functionMap) {
			functionMap[placement]();
		} else {
			throw new Error(`Unknown placement setting '${placement}'`);
		}
		return { ctaSpec, $ctaContainer };
	} else {
		return {};
	}
};

export const containerClassFromCtaType = (ctaType) =>
	({
		creditApp: 'autofi-creditapp-container',
		deposit: 'autofi-deposit-container',
		prequalify: 'autofi-prequalify-button-container',
		driveTogether: 'drive-together-button-container',
	}[ctaType] || 'autofi-button-container');

export const makeOverrideDataset = (buttonSpec) => {
	const {
		borderRadius,
		buttonTextOverride,
		colors,
		pathwayHeaderOverride,
		pathwayHeaderOverrideI18n,
		pathwaySubHeaderOverride,
		pathwaySubHeaderOverrideI18n,
	} = buttonSpec;

	return pickBy(
		{
			autofiBorderRadius: borderRadius,
			autofiButtonTextOverride: buttonTextOverride,
			autofiColors: colors && JSON.stringify(colors),
			autofiPathwayTitle: pathwayHeaderOverride,
			autofiPathwayTitleI18n: pathwayHeaderOverrideI18n && JSON.stringify(pathwayHeaderOverrideI18n),
			autofiPathwaySubTitle: pathwaySubHeaderOverride,
			autofiPathwaySubTitleI18n: pathwaySubHeaderOverrideI18n && JSON.stringify(pathwaySubHeaderOverrideI18n),
		},
		(x) => x
	);
};

export const getBuiltInIframe = () => {
	// Return iframe that can be used to access browser's built-in functions on
	// sites that override them. See the following URLs:
	//   https://stackoverflow.com/q/8580431/28324
	//   https://stackoverflow.com/q/8044433/28324
	// This function tries to reuse the iframe it creates to avoid the
	// performance cost of creating and destroying iframes, which can make some
	// sites completely unresponsive on iOS.
	let $iframe = document.getElementById('autofi-built-in-function-iframe');
	if (!$iframe) {
		$iframe = document.createElement('iframe');
		$iframe.setAttribute('id', 'autofi-built-in-function-iframe');
		$iframe.style.display = 'none';
		document.body.appendChild($iframe);
	}
	return $iframe;
};

export const setTimeout = (...args) => {
	// Return the browser's built-in setTimeout function, even if it is
	// overridden, as it is on some dealer sites (apparently because of
	// New Relic), which makes debugging annoyingly tedious by making the
	// browser debugger show a bunch of cryptic New Relic stuff in the stack
	// trace, mixed with, or sometimes in place of, calls to our functions.
	const $iframe = getBuiltInIframe();
	return $iframe.contentWindow.setTimeout.apply(window, args);
};

export const promiseAll = (...args) => {
	// Return the browser's built-in Promise.all function, even if it is
	// overridden, as it is on DealerOn sites, making it throw mysterious
	// exceptions. If the following line of code throws an exception, then we
	// are on a site that badly overrides Promise.all:
	// Promise.all([1, 2, 3].map(async x => x + 1))
	const $iframe = getBuiltInIframe();
	return $iframe.contentWindow.Promise.all(...args);
};

export const makeEmptyVehicle = (vin) => {
	return {
		status: VehicleStatus.Pending,
		ui: { autofiCtaStuff: [] },
		vin,
	};
};
