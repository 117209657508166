import { h, Component } from 'preact';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import VMasker from 'vanilla-masker';

import styles from './prettyInput.styl';
import * as utils from '~ui/utils';
import withStyles from '~ui/components/withStyles';

const zeroWidthSpace = '\u200b';

class PrettyInput extends Component {
	static defaultProps = { type: 'text', hint: undefined };

	state = { value: undefined, touched: false };

	getPattern = () => {
		if (this.props.pattern) {
			return this.props.pattern;
		} else if (this.props.type === 'tel') {
			// phone number pattern copied from public/js/validate.js in models repo,
			// with minor changes.
			return utils.validationPatterns.phone;
		} else if (this.props.type === 'email') {
			return utils.validationPatterns.email;
		} else {
			return this.props.pattern;
		}
	};

	onInput = (event) => {
		this.props.onInput(event);
		let inputValue = event.target.value;
		if (this.props.type === 'text' && this.props.maskType === 'money') {
			const value = utils.parseMoneyString(inputValue);
			inputValue =
				value !== 'NaN'
					? VMasker.toMoney(value, {
							precision: 0,
							separator: '.',
							delimiter: ',',
							unit: '$',
					  })
					: '';
		}
		this.setState({ value: inputValue, touched: true });
	};

	componentDidMount = () => {
		this.setState({ value: this.input.value });
		if (this.props.type === 'tel') {
			VMasker(this.input).maskPattern('(999) 999-9999');
		}
	};

	render = (props, state) => {
		// onKeyup attribute is to update validity if VMasker changes the value
		// after a key is pressed.
		// TODO: find a less hacky way to do this.

		const containerClasses = compact([
			'prettyInput',
			state.value ? null : 'empty',
			state.touched ? 'touched' : null,
			props.containerClass,
		]).join(' ');

		return (
			<div class={containerClasses}>
				<div class="inputWrapper">
					{this.props.type === 'tel' && <span class="phonePrefix">+1</span>}
					<input
						{...omit(props, ['containerClass', 'onInput', 'pattern'])}
						onInput={this.onInput}
						onKeyup={(event) => {
							const oldTarget = event.target;
							setTimeout(() => this.onInput({ ...event, target: oldTarget }), 50);
						}}
						ref={(input) => (this.input = input)}
						pattern={this.getPattern()}
						value={this.state.value}
					/>
				</div>
				<label>{props.placeholder}</label>
				<div class="hint">{props.hint || zeroWidthSpace}</div>
			</div>
		);
	};
}

export default withStyles(PrettyInput, styles);
