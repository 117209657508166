import defaultTo from 'lodash/defaultTo';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import { translate } from 'preact-i18n';

import { vehiclelessCtaTypes, VehicleStatus } from '~lib/enum';
import getI18nDiscount from '~lib/dictionaries/getI18nDiscount';
import loadDictionary from '~lib/dictionaries/loadDictionary';
import * as utils from '~lib/scraper/utils';
import { fetchVehicles } from '~lib/scraper/vehicleService';
import * as uiUtils from '~ui/utils';
import { getShiftDigitalSessionId } from '~ui/utils/analytics';

export default class Scraper {
	SCRAPE_INTERVAL_MS = 500; // how often to scrape

	fetchIsInProgress = false;

	vinVehicleMap = {};

	constructor(ui, autofiData) {
		this.ui = ui;
		this.autofiData = autofiData;

		if (get(window, 'DDC.API')) {
			// TODO: Move DDC_API_KEY to settings collection in db if this ever becomes
			// more secure
			const DDC_API_KEY = 'autofi';
			this.ddcApi = new window.DDC.API(DDC_API_KEY);
		}
	}

	async getVehicleListItems() {
		if (this.shouldUseDdcApi()) {
			// Getting the vehicle list items on sites using DDC's API is tricky, and
			// possibly not even meaningful, since we don't scrape for vehicle list
			// items.
			return null;
		} else {
			const selectors = await this.getSelectors();
			return utils.selectNodes(document, selectors.vehicles).filter(utils.nodeIsVisible);
		}
	}

	/**
	 * @param {HTMLElement} $container
	 */
	async extractVehicleInfo($container) {
		try {
			const selectors = await this.getSelectors();
			const vin = utils.extractDataFromSpecs(selectors.vin, $container);
			const dealerCTAs = utils.getDealerCTAsFromSpecs(
				selectors.ctas.filter((s) => !vehiclelessCtaTypes.includes(s.ctaType)),
				$container
			);

			// TODO: consider placing our button somewhere else, maybe in the UI, to
			// make this function free of side effects.
			let { $ctaContainer, ctaSpec } = this.placeOurStyleExplorePaymentsButton(selectors.autofiButton, $container);

			let datasetColorJson = get($ctaContainer, 'dataset.autofiColors');
			const autofiCtaStuff = [
				{
					$ctaContainer,
					borderRadius: get($ctaContainer, 'dataset.autofiBorderRadius') || get(ctaSpec, 'borderRadius'),
					buttonTextOverride:
						get($ctaContainer, 'dataset.autofiButtonTextOverride') || get(ctaSpec, 'buttonTextOverride'),
					colors: datasetColorJson ? JSON.parse(datasetColorJson) : get(ctaSpec, 'colors'),
					ctaType: 'startApplication',
					usesDdcLocation: false,
				},
			];
			const depositCtaData = this.placeOurStyleDepositPaymentsButton(selectors.autofiButton, $container);
			$ctaContainer = depositCtaData.$ctaContainer;
			ctaSpec = depositCtaData.ctaSpec;
			datasetColorJson = get($ctaContainer, 'dataset.autofiColors');
			let depositCtaColor;
			if (datasetColorJson) {
				depositCtaColor = JSON.parse(datasetColorJson);
			} else if (get(ctaSpec, 'colors')) {
				depositCtaColor = JSON.parse(get($ctaContainer, 'dataset.autofiColors'));
			} else {
				depositCtaColor = get(ctaSpec, 'colors');
			}
			if ($ctaContainer) {
				autofiCtaStuff.push({
					$ctaContainer,
					borderRadius: get($ctaContainer, 'dataset.autofiBorderRadius') || get(ctaSpec, 'borderRadius'),
					buttonTextOverride:
						get($ctaContainer, 'dataset.autofiButtonTextOverride') || get(ctaSpec, 'buttonTextOverride'),
					colors: depositCtaColor,
					ctaType: 'deposit',
					usesDdcLocation: false,
				});
			}

			const scrapedSalePrice = isEmpty(selectors.salePrice)
				? undefined
				: utils.parseSalePrice(utils.extractDataFromSpecs(selectors.salePrice, $container));

			// Place Prequalify iFrame Button
			const prequalifyCtaData = this.placePrequalifyButton(selectors.autofiButton, $container);
			$ctaContainer = prequalifyCtaData.$ctaContainer;
			if ($ctaContainer) {
				autofiCtaStuff.push({ $ctaContainer, ctaType: 'prequalify' });
			}

			// Place Drive Together iFrame Button
			const driveTogetherCtaData = this.placeDriveTogetherButton(selectors.autofiButton, $container);
			$ctaContainer = driveTogetherCtaData.$ctaContainer;
			if ($ctaContainer) {
				autofiCtaStuff.push({ $ctaContainer, ctaType: 'driveTogether' });
			}

			return {
				scrapedSalePrice,
				status: VehicleStatus.Pending,
				ui: { $container, autofiCtaStuff, dealerCTAs },
				vdpUrl: selectors.vdpUrl ? utils.extractDataFromSpecs(selectors.vdpUrl, $container) : window.location.href,
				vin,
			};
		} catch (err) {
			// eslint-disable-next-line no-console
			console.warn(err);
			return undefined;
		}
	}

	async getVehicles() {
		if (this.shouldUseDdcApi()) {
			return (await this.ddcApi.utils.getVehicleData()).map(({ vin }) => this.getVehicleForDdc(vin)).filter(Boolean);
		} else {
			return (
				await utils.promiseAll(
					(
						await this.getVehicleListItems()
					).map(async ($el) => {
						const scrapedVehicleInfo = await this.extractVehicleInfo($el);
						const { ui, vin } = scrapedVehicleInfo;
						const cachedVehicleData = this.vinVehicleMap[vin];
						return cachedVehicleData ? { ...cachedVehicleData, ui } : scrapedVehicleInfo;
					})
				)
			).filter(Boolean);
		}
	}

	/**
	 * Returns a DOM node to contain an explore payments button in our style
	 * @param {[object]} specs array of autofiButtonSpec objects to be filtered by ctaType
	 * @param {Node} $vehicleContainer dom node containing a particular vehicle
	 * @return {object} matching buttonSpec, if any, and container where the button is going
	 */
	// eslint-disable-next-line default-param-last
	placeOurStyleExplorePaymentsButton = (specs = [], $vehicleContainer) => {
		// TODO: make this function async so we can easily test that it adds the
		// class for the pageType. Or maybe do this inline in extractVehicleInfo.
		const { ctaSpec, $ctaContainer } = utils.placeButtonContainerFromSpecs(
			specs,
			'startApplication',
			$vehicleContainer
		);
		if ($ctaContainer) {
			this.getPageType().then((pageType) => $ctaContainer.classList.add(pageType));
		}
		return { ctaSpec, $ctaContainer };
	};

	// eslint-disable-next-line default-param-last
	placeOurStyleDepositPaymentsButton = (specs = [], $vehicleContainer) => {
		const { ctaSpec, $ctaContainer } = utils.placeButtonContainerFromSpecs(specs, 'deposit', $vehicleContainer);
		if ($ctaContainer) {
			this.getPageType().then((pageType) => $ctaContainer.classList.add(pageType));
		}
		return { ctaSpec, $ctaContainer };
	};

	// eslint-disable-next-line default-param-last
	placePrequalifyButton = (specs = [], $vehicleContainer) => {
		const { ctaSpec, $ctaContainer } = utils.placeButtonContainerFromSpecs(specs, 'prequalify', $vehicleContainer);
		if ($ctaContainer) {
			this.getPageType().then((pageType) => $ctaContainer.classList.add(pageType));
		}
		return { ctaSpec, $ctaContainer };
	};

	// eslint-disable-next-line default-param-last
	placeDriveTogetherButton = (specs = [], $vehicleContainer) => {
		const { ctaSpec, $ctaContainer } = utils.placeButtonContainerFromSpecs(specs, 'driveTogether', $vehicleContainer);
		if ($ctaContainer) {
			this.getPageType().then((pageType) => $ctaContainer.classList.add(pageType));
		}
		return { ctaSpec, $ctaContainer };
	};

	placeVehiclelessCtaContainer = (selectors, ctaType) => {
		const { $ctaContainer } = utils.placeButtonContainerFromSpecs(selectors.autofiButton, ctaType);
		if ($ctaContainer) {
			this.getPageType().then((pageType) => $ctaContainer.classList.add(pageType));
		}
		return $ctaContainer;
	};

	// Call this.ddcApi.utils.getPageData as little as possible, because DDC
	// complains if we call it too much.
	getDdcPageType = memoize(async () => {
		const pageData = await this.ddcApi.utils.getPageData();
		if (pageData.searchPage) {
			return 'listings';
		} else if (pageData.detailPage) {
			return 'details';
		} else {
			return 'other';
		}
	});

	getPageType = async () => {
		const { websiteSettings } = this.autofiData.dealer;
		const vehicleListingsSelector = websiteSettings.scraper.listings.selectors.vehicles || null;

		if (get(websiteSettings, 'api.enabled', false)) {
			return 'api';
		} else if (this.shouldUseDdcApi()) {
			return this.getDdcPageType();
		} else if (document.querySelector(vehicleListingsSelector)) {
			return 'listings';
		} else if (utils.extractDataFromSpecs(websiteSettings.scraper.details.selectors.vin)) {
			return 'details';
		} else {
			return 'other';
		}
	};

	getSelectors = async () => {
		const pageType = await this.getPageType();
		const { websiteSettings } = this.autofiData.dealer;
		const selectorMap = {
			...get(websiteSettings.scraper[pageType], 'selectors', {}),
			...(pageType === 'details' ? { vehicles: 'body' } : {}),
		};

		return {
			autofiButton: [],
			ctas: [],
			...mapValues(selectorMap, (selectors) => {
				return Array.isArray(selectors) ? selectors.filter((s) => s.enabled) : selectors;
			}),
		};
	};

	prepareVehicle = (vehicle) => {
		const { launchDarklyFeatureFlags } = this.autofiData;
		// if one vehicle is listed by two dealers or we have one feed for 2 dealers
		// make sure we're displaying the correct discount name
		const dealerName = get(this.autofiData, 'dealer.name', '');
		const I18nDiscount = getI18nDiscount(dealerName);
		const salePrice = defaultTo(
			vehicle.scrapedSalePrice,
			vehicle.dealerRetailPrice - sumBy(vehicle.discounts, 'amount')
		);

		const discountCodesToIgnore = ['FEP'];

		return {
			...vehicle,
			salePrice,
			...(!launchDarklyFeatureFlags.enableVehicleSearchV2 && {
				discounts: Object.values(vehicle.discounts || {}).map((discount) => {
					if (discountCodesToIgnore.includes(discount.code)) {
						return discount;
					}
					return {
						...discount,
						description: I18nDiscount.en,
						descriptionI18n: I18nDiscount,
						title: I18nDiscount.en,
						titleI18n: I18nDiscount,
					};
				}),
			}),
			// Deprecated price property is for smart cow. TODO: remove this.
			price: salePrice,
		};
	};

	render = async (data) => {
		(data?.vehicles || []).forEach((vehicle) => (this.vinVehicleMap[vehicle.vin] = vehicle));
		const pageType = await this.getPageType();
		this.ui.render({ pageType, ...data }, this.autofiData);
	};

	/**
	 * Render any vehicle-less CTAs to be injected and/or the supplied vehicles.
	 * Includes the call to the vehicle service to get complete vehicle data
	 * @param {object} scrapedData data to be rendered
	 * @param {{ctaType: () => Node}} scrapedData.autofiVehiclelessCtaMap
	 * @param {{ctaType: Node}} scrapedData.dealerVehiclelessCtaMap
	 * @param {[object]} scrapedData.vehicles
	 */
	fetchAndRender = async (scrapedData) => {
		const { vehicles } = scrapedData;
		this.render(scrapedData);

		this.fetchIsInProgress = true;
		const completeVehicles = await fetchVehicles(vehicles, this.autofiData);
		this.fetchIsInProgress = false;

		const vinMap = keyBy(completeVehicles, 'vin');
		const preparedVehicles = vehicles.map((v) => (v.vin in vinMap ? this.prepareVehicle(vinMap[v.vin]) : v));
		this.render({ vehicles: preparedVehicles });
	};

	/**
	 * Check that the vehicle has everything needed to render
	 */
	// eslint-disable-next-line class-methods-use-this
	isValidVehicle = (vehicle) => Boolean(vehicle.vin);

	getDataToRender = async () => {
		const selectors = await this.getSelectors();
		const dealerVehiclelessCtaSpecs = selectors.ctas.filter((s) => vehiclelessCtaTypes.includes(s.ctaType));
		return {
			autofiVehiclelessCtaMap: {
				creditApp: () => this.placeVehiclelessCtaContainer(selectors, 'creditApp'),
				standAloneCreditApp: () => this.placeVehiclelessCtaContainer(selectors, 'standAloneCreditApp'),
			},
			dealerVehiclelessCtaMap: utils.getDealerCTAsFromSpecs(dealerVehiclelessCtaSpecs),
			vehicles: (await this.getVehicles()).filter(this.isValidVehicle),
		};
	};

	addDdcLocationCtas = async (ctaSpecs) => {
		const pageType = await this.getPageType();

		const autofiVehiclelessCtaMap = {};

		const locationSpecs = ctaSpecs.filter(
			(spec) => spec.enabled && uiUtils.ddcApiLocationFromPlacement(spec.placement)
		);

		const locationCtaSpecsMap = groupBy(locationSpecs, (spec) => uiUtils.ddcApiLocationFromPlacement(spec.placement));

		forEach(locationCtaSpecsMap, (specs, location) => {
			this.ddcApi.insertOnce(location, ($container, meta) => {
				const { vin } = meta;
				const vehicle = this.getVehicleForDdc(vin);
				if (vehicle) {
					const vehicleSpecs = specs.filter(
						(spec) => !vehiclelessCtaTypes.includes(spec.ctaType) || location === 'vehicle-pricing'
					);

					vehicleSpecs.forEach((ctaSpec) => {
						const { borderRadius, colors, buttonTextOverride, ctaType } = ctaSpec;

						const $ctaContainer = utils.makeButtonContainer(ctaSpec);
						$ctaContainer.classList.add(pageType);

						this.ddcApi.append($container, $ctaContainer);

						vehicle.ui.autofiCtaStuff.push({
							$ctaContainer,
							borderRadius,
							colors,
							buttonTextOverride,
							ctaType,
							usesDdcLocation: true,
						});
					});

					this.render({ vehicles: [vehicle] });
				}

				if (location !== 'vehicle-pricing') {
					Object.assign(
						autofiVehiclelessCtaMap,
						Object.fromEntries(
							vehiclelessCtaTypes
								.map((ctaType) => specs.find((spec) => spec.ctaType === ctaType))
								.filter(Boolean)
								.map((spec) => [
									spec.ctaType,
									() => {
										const $existingCtaContainer = $container.querySelector('.ddc-style');
										if ($existingCtaContainer) {
											return $existingCtaContainer;
										}
										const $ctaContainer = utils.makeButtonContainer(spec);
										$ctaContainer.classList.add(pageType, 'ddc-style');
										this.ddcApi.append($container, $ctaContainer);
										return $ctaContainer;
									},
								])
						)
					);

					if (!isEmpty(autofiVehiclelessCtaMap)) {
						this.render({ autofiVehiclelessCtaMap });
					}
				}
			});
		});
	};

	addDdcIntentCtas = async (ctaSpecs) => {
		const pageType = await this.getPageType();
		const { dealer } = this.autofiData;
		const { brand, websiteSettings } = dealer;
		const { buttonTextOverrideI18n } = get(websiteSettings.ui, pageType, {});

		ctaSpecs
			.filter((spec) => spec.enabled && uiUtils.ddcApiIntentFromPlacement(spec.placement))
			.forEach((ctaSpec) => {
				const { borderRadius, colors, buttonTextOverride: individualButtonTextOverride, ctaType, placement } = ctaSpec;
				const intent = uiUtils.ddcApiIntentFromPlacement(placement);
				const defaultButtonTextId = uiUtils.ctaTypeDefaultTextIdMap[ctaType];

				const text = Object.fromEntries(
					['en_US', 'en_CA', 'fr_CA'].map((ddcLocale) => {
						// TODO: avoid duplicating logic in lib/ui/components/vehicleAutofiCTAs.jsx
						const locale = ddcLocale.replace(/_/g, '-');
						const language = uiUtils.languageFromLocale(locale);
						const dictionary = loadDictionary(locale);

						let buttonText;
						if (individualButtonTextOverride) {
							buttonText = individualButtonTextOverride;
						} else if (ctaType === 'startApplication') {
							buttonText =
								get(buttonTextOverrideI18n, locale) ||
								get(buttonTextOverrideI18n, language) ||
								dictionary[defaultButtonTextId];
						} else if (ctaType === 'privateOffers') {
							const id =
								uiUtils.ctaTypeDefaultTextIdMap.privateOffers + (brand === 'non-franchised' ? '' : '-with-brand');
							buttonText = translate(id, null, dictionary, { brand: startCase(brand) });
						} else {
							buttonText = dictionary[defaultButtonTextId];
						}

						return [ddcLocale, buttonText];
					})
				);

				const dataset = utils.makeOverrideDataset(ctaSpec);
				const backgroundColor = get(colors, 'background');
				const textColor = get(colors, 'text');
				const attributes = {
					...mapKeys(
						dataset,
						(value, attr) => `data-${attr.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)}`
					),
					'data-autofi-cta-type': ctaType,
					style:
						[
							...(backgroundColor ? [`background: ${backgroundColor}`] : []),
							...(borderRadius ? [`border-radius: ${borderRadius}`] : []),
							...(textColor ? [`color: ${textColor}`] : []),
						].join(';') || undefined,
					// TODO: add data-vin for testing convenience?
				};

				const primaryCtaTypes = ['creditApp', 'standAloneCreditApp', 'startApplication', 'deposit'];
				const buttonType = primaryCtaTypes.includes(ctaType) ? 'primary' : 'default';

				// eslint-disable-next-line consistent-return
				this.ddcApi.insertCallToActionOnce('button', intent, (meta) => {
					const { vin } = meta;
					const vehicle = this.getVehicleForDdc(vin);

					if (!vehicle || uiUtils.shouldRenderForVehicle(vehicle, dealer, window.location.href)) {
						return {
							attributes,
							classes: 'autofi-cta btn btn-block btn-sm text-center',
							onclick: (clickEvent) => {
								const mainUI = this.ui.uiInstance;
								mainUI.ctaTypeClickHandlerMap[ctaType](vin, { clickEvent });
							},
							text,
							type: buttonType,
						};
					}
				});
			});
	};

	addDdcCtas = (ctaSpecs) => {
		this.addDdcLocationCtas(ctaSpecs);
		this.addDdcIntentCtas(ctaSpecs);
	};

	getVehicleForDdc = (vin) => {
		if (vin && !(vin in this.vinVehicleMap)) {
			this.vinVehicleMap[vin] = utils.makeEmptyVehicle(vin);
		}
		return this.vinVehicleMap[vin];
	};

	initializeDdcApi = async () => {
		const { alwaysShowLeadgen } = this.autofiData.dealer;

		// Make our UI available before vehicle data is received.
		this.render({});

		const selectors = await this.getSelectors();

		const relevantSpecs = selectors.autofiButton.filter(
			(spec) => spec.enabled && uiUtils.placementUsesDdcApi(spec.placement)
		);

		const [vehicleSpecificSpecs, pageSpecificSpecs] = partition(relevantSpecs, (spec) => {
			const intent = uiUtils.ddcApiIntentFromPlacement(spec.placement);
			const location = uiUtils.ddcApiLocationFromPlacement(spec.placement);
			return intent || (location && !['primary-banner', 'secondary-content', 'content'].includes(location));
		});

		this.ddcApi.subscribe('vehicle-data-updated-v1', async (data) => {
			if (alwaysShowLeadgen) {
				this.addDdcCtas(vehicleSpecificSpecs);
			}

			const vins = data.payload.vehicleData.map((vehicle) => vehicle.vin).filter(Boolean);
			const vehicles = vins.map((vin) => this.getVehicleForDdc(vin));

			this.fetchIsInProgress = true;
			const completeVehicles = await fetchVehicles(vehicles, this.autofiData);
			this.fetchIsInProgress = false;

			const vinCompleteVehicleMap = keyBy(completeVehicles, 'vin');
			const preparedVehicles = vehicles.map((v) =>
				v.vin in vinCompleteVehicleMap ? this.prepareVehicle(vinCompleteVehicleMap[v.vin]) : v
			);
			Object.assign(this.vinVehicleMap, keyBy(preparedVehicles, 'vin'));

			this.render({ vehicles: preparedVehicles });

			if (!alwaysShowLeadgen) {
				this.addDdcCtas(vehicleSpecificSpecs);
			}
		});

		this.ddcApi.subscribe('page-load-v1', () => {
			this.addDdcCtas(pageSpecificSpecs);
		});
	};

	scrapeOnce = async () => {
		getShiftDigitalSessionId().then((shiftDigitalSessionId) => {
			if (shiftDigitalSessionId) {
				this.render({ shiftDigitalSessionId });
			}
		});

		let scrapedData;
		if (!this.fetchIsInProgress) {
			scrapedData = await this.getDataToRender();
			await this.fetchAndRender(scrapedData);
		}
		return scrapedData;
	};

	/**
	 * The main scrape function:
	 * - Scrape the whole page as it currently exists
	 * - Call render to render any vehicleless CTAs and/or vehicles that haven't been seen before
	 * - Set up a timer to scrape again in case any new vehicles show up
	 */
	scrape = async () => {
		this.scrapeOnce();
		utils.setTimeout(this.scrape, this.SCRAPE_INTERVAL_MS);
	};

	shouldUseDdcApi = () => {
		if (this.ddcApi) {
			const { details, listings, other } = this.autofiData.dealer.websiteSettings.scraper;

			const hasNonDdcAutofiButtonSelectors = [
				...details.selectors.autofiButton,
				...listings.selectors.autofiButton,
				...get(other, 'selectors.autofiButton', []),
			].some((selector) => selector.enabled && !uiUtils.placementUsesDdcApi(selector.placement));

			const hasDealerCTASelectors = [
				...details.selectors.ctas,
				...listings.selectors.ctas,
				...get(other, 'selectors.ctas', []),
			].some((selector) => selector.enabled);
			return !hasNonDdcAutofiButtonSelectors && !hasDealerCTASelectors;
		}
		return false;
	};

	prepareToScrape = () => {
		const { websiteSettings } = this.autofiData.dealer;

		if (get(websiteSettings, 'api.enabled', false)) {
			// do not scrape for data when using pathways API
			this.render(null);
		} else if (this.shouldUseDdcApi()) {
			this.initializeDdcApi();
		} else {
			this.scrape();
		}
	};

	// scrape once content is loaded
	run = () => {
		if (['interactive', 'complete'].includes(document.readyState)) {
			this.prepareToScrape();
		} else {
			document.addEventListener('DOMContentLoaded', this.prepareToScrape);
		}
	};
}
